import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { SharedHeaderComponent } from "./shared-header.component";
import { MobileSideMenuModule } from "booking-app-v2/shared/components/mobile-side-menu/mobile-side-menu.module";
import { LocaleDropdownModule } from "booking-app-v2/shared/components/locale-dropdown/locale-dropdown.module";
import { CurrencyDropdownModule } from "booking-app-v2/shared/components/currency-dropdown/currency-dropdown.module";
import { ProductTypeSwitchModule } from "booking-app-v2/shared/components/product-type-switch/product-type-switch.module";

@NgModule({
  declarations: [ SharedHeaderComponent ],
  imports: [
    SharedModule,
    CurrencyDropdownModule,
    LocaleDropdownModule,
    MobileSideMenuModule,
    ProductTypeSwitchModule,
  ],
  exports: [ SharedHeaderComponent ],
  providers: [],
})
export class SharedHeaderModule {}
