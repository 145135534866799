import { Injectable } from "@angular/core";
import { CURRENT_PAGE, CurrentPage } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class PageDiscoveryService {

  public currentPage(): CurrentPage {
    const path = location.pathname;
    if (path === "/") {
      return CURRENT_PAGE.HOME;
    } else if (path === "/hotels") {
      return CURRENT_PAGE.HOTEL_HOME;
    } else if (path === "/cars") {
      return CURRENT_PAGE.CAR_HOME;
    } else if (path === "/flights") {
      return CURRENT_PAGE.FLIGHT_HOME;
    } else if (path === "/complimentary_nights") {
      return CURRENT_PAGE.COMPLIMENTARY_NIGHTS;
    } else if (path === "/account/booking") {
      return CURRENT_PAGE.MANAGE_BOOKING;
    } else if (path.includes("contact_us")) {
      return CURRENT_PAGE.CONTACT_US;
    } else if (path.includes("faq")) {
      return CURRENT_PAGE.FAQ;
    } else if (path.includes("conditions")) {
      return CURRENT_PAGE.TERMS_CONDITION;
    } else if (path.includes("privacy-policy")) {
      return CURRENT_PAGE.PRIVACY_POLICY;
    } else if (path.includes("privacy-collection")) { // Only for Bounty
      return CURRENT_PAGE.PRIVACY_COLLECTION;
    } else if (path === "/cars/results") { // Cars
      return CURRENT_PAGE.CAR_RESULT;
    } else if (path.includes("/cars/details")) {
      return CURRENT_PAGE.CAR_DETAIL;
    } else if (path.includes("/cars/checkout")) {
      return CURRENT_PAGE.CAR_CHECKOUT;
    } else if (path.includes("cars/bookings")) {
      return CURRENT_PAGE.CAR_SUCCESS;
    } else if (path === "/flights/results") { // Flights
      return CURRENT_PAGE.FLIGHT_RESULT;
    } else if (path === "/flights/summary") {
      return CURRENT_PAGE.FLIGHT_DETAIL;
    } else if (path === "/flights/checkout") {
      return CURRENT_PAGE.FLIGHT_CHECKOUT;
    } else if (path.includes("/flights/bookings")) {
      return CURRENT_PAGE.FLIGHT_SUCCESS;
    } else if (path.includes("/results/")) { // Hotels
      return CURRENT_PAGE.SEARCH_RESULT;
    } else if (path.includes("/hotels/detail")) {
      return CURRENT_PAGE.HOTEL_DETAIL;
    } else if (path.includes("/hotels/checkout")) {
      return CURRENT_PAGE.CHECKOUT;
    } else if (path.includes("/bookings/")) {
      return CURRENT_PAGE.CONFIRMATION;
    } else if (path.includes("company") ||
      path.includes("terms") ||
      path.includes("press")) {
      return CURRENT_PAGE.STATIC_CONTENT;
    } else {
      return CURRENT_PAGE.HOME;
    }
  }
}
