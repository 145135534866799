import { Injectable } from "@angular/core";
import {
  Banner,
  PRODUCT_TYPE,
  ProductType,
  TravelType,
} from "booking-app-v2/shared/types";
import {
  Currency,
  LandingPage,
  Locale,
  PointsPartner,
  PointsPartners,
  User,
} from "booking-app-v2/shared/models";
import { HotelSearchForm } from "booking-app-v2/hotels/models";
import { FlightSearchForm } from "booking-app-v2/flights/models";
import { CarSearchForm } from "booking-app-v2/cars/models";

@Injectable({
  providedIn: "root",
})
export class GlobalData {
  private readonly _globalData: GlobalDataModel;

  constructor() {
    this._globalData = new GlobalDataModel();
  }

  get<globalDataKey extends keyof GlobalDataModel>(
    globalDataKey: globalDataKey,
  ): GlobalDataModel[globalDataKey] {
    return this._globalData[globalDataKey];
  }

  set<globalDataKey extends keyof GlobalDataModel>(
    globalDataKey: globalDataKey,
    globalDataValue: GlobalDataModel[globalDataKey],
  ): void {
    this._globalData[globalDataKey] = globalDataValue;
  }

  isUserLoggedIn(): boolean {
    return !!this._globalData.currentUser;
  }

  productTypeAdapter(productType: ProductType): string {
    switch (productType) {
      case PRODUCT_TYPE.REDEEM:
        return "pay_with_points";
      case PRODUCT_TYPE.CASHVOUCHER:
        return "cash_voucher_earn";
      case PRODUCT_TYPE.VOUCHER:
        return "free_night";
      case PRODUCT_TYPE.COMPLIMENTARY_NIGHTS:
        return "complimentary_nights";
      default:
        return productType;
    }
  }
}

class GlobalDataModel {
  reservedUrlKeywords: string[] = [
    "html",
    "company",
    "hotels",
    "search",
    "users",
    "purchase",
    "results",
    "press",
    "terms",
    "purchases",
    "account",
    "context.html",
    "admin",
    "flights",
    "cars",
    "bookings",
  ];
  selectedLocale: Locale;
  enabledLocales: Locale[];
  productType: ProductType;
  selectedCurrency: Currency;
  currencies: Currency[];
  rate: number;
  pointsPartner: PointsPartner;
  pointsPartners: PointsPartners;
  landingPage: LandingPage;
  currentUser: User;
  travelType: TravelType;
  showMobileSideMenu: boolean;
  taxInclusive: boolean;
  hotelSearchForm: HotelSearchForm;
  selectedCountrySite: { code: string };
  isNewSearch: boolean;
  hotelResultStillPolling: boolean;
  flightResultStillPolling: boolean;
  carResultStillPolling: boolean;
  displayAlignment: "ltr" | "rtl";
  isLoading: boolean;
  bookingKeysBlackList: string[] = [];
  banners: Banner[];
  flightSearchForm: FlightSearchForm;
  hideUseAddressFromProfile: boolean;
  pointsAccountId: string;
  carSearchForm: CarSearchForm;
  isRevalidating: boolean = false;
  isPriceChanged: boolean = false;
}
