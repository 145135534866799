export enum GlobalDataEnum {
  RESERVED_URL_KEYWORDS = "reservedUrlKeywords",
  SELECTED_LOCALE = "selectedLocale",
  ENABLED_LOCALES = "enabledLocales",
  SELECTED_CURRENCY = "selectedCurrency",
  ENABLED_CURRENCIES = "currencies",
  CONVERT_RATE = "rate",
  POINTS_PARTNER = "pointsPartner",
  POINTS_PARTNERS = "pointsPartners",
  LANDING_PAGE = "landingPage",
  PRODUCT_TYPE = "productType",
  CURRENT_USER = "currentUser",
  TRAVEL_TYPE = "travelType",
  SHOW_MOBILE_SIDE_MENU = "showMobileSideMenu",
  TAX_INCLUSIVE = "taxInclusive",
  HOTEL_SEARCH_FORM = "hotelSearchForm",
  SELECTED_COUNTRY_SITE = "selectedCountrySite",
  IS_NEW_SEARCH = "isNewSearch",
  HOTEL_RESULT_STILL_POLLING = "hotelResultStillPolling",
  FLIGHT_RESULT_STILL_POLLING = "flightResultStillPolling",
  CAR_RESULT_STILL_POLLING = "carResultStillPolling",
  DISPLAY_ALIGNMENT = "displayAlignment",
  IS_LOADING = "isLoading",
  BOOKING_KEYS_BLACK_LIST = "bookingKeysBlackList",
  BANNERS = "banners",
  FLIGHT_SEARCH_FORM = "flightSearchForm",
  POINTS_ACCOUNT_ID = "pointsAccountId",
  CAR_SEARCH_FORM = "carSearchForm",
  IS_REVALIDATING = "isRevalidating",
  IS_PRICE_CHANGED = "isPriceChanged",
}
