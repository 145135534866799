import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BottomSheetService } from "booking-app-v2/shared/services/bottom-sheet.service";
import { RoomGuestFieldComponent } from "booking-app-v2/shared/components/room-guest-field/room-guest-field.component";

@Component({
  selector: "room-guest-field-mobile",
  template: `
    <div class="room-guest-field-mobile">
      <div class="room-guest-field-mobile-input" (click)="beginSelection()"
         [attr.aria-label]="summaryTextAriaLabel" role="button">
        <button class="open-guest-input" type="button"
                [attr.aria-label]="'Open guest input'"
                (click)="beginSelection()" >
          <ng-content select="icon-content"></ng-content>
        </button>
        <input class="field-input"
               type="text"
               readonly
               [value]="summaryText | parseHtmlInInput"
               (keyup.enter)="beginSelection()"
               *ngIf="!labelOnlySummaryText">
        <span class="field-input"
              [innerHTML]="summaryText"
              *ngIf="labelOnlySummaryText">
        </span>
      </div>

      <ng-template #roomGuestFieldMobile>
        <bottom-sheet-wrapper>
          <div class="summary-text" bottomSheetTitle>
            <span [innerHTML]="summaryText"></span>
          </div>
          <div class="counters-container" bottomSheetBody>
            <room-guest-counter
              [roomCount]="roomCount"
              [adultCount]="adultCount"
              [childCount]="childCount"
              [childrenAges]="childrenAges"
              (onUpdateRoomCount)="updateRoomCount($event)"
              (onUpdateAdultCount)="updateAdultCount($event)"
              (onUpdateChildCount)="updateChildCount($event)"
              (onUpdateChildrenAges)="updateChildrenAges($event)"
              (closeModal)="closeModal()"
            ></room-guest-counter>
            <button type="button"
                    class="btn-action"
                    (click)="closeModal()">
              <span> {{ "button.done" | translate }} </span>
            </button>
          </div>
        </bottom-sheet-wrapper>
      </ng-template>
    </div>
  `,
})
export class RoomGuestFieldMobileComponent extends RoomGuestFieldComponent {
  @Input() roomCount: number;
  @Input() adultCount: number;
  @Input() childCount: number;
  @Input() childrenAges: number[];
  @Input() isShowingTotalGuest: boolean;

  @Output() onUpdateRoomCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateAdultCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateChildCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateChildrenAges: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ViewChild("roomGuestFieldMobile") roomGuestFieldMobileTemplate: TemplateRef<any>;

  // local state
  modalOpen: boolean = false;
  labelOnlySummaryText: boolean = this.appSettingsService.roomGuestParameters.labelOnlySummaryText;

  constructor(
    translateService: TranslateService,
    appSettingsService: AppSettingsService,
    private bottomSheetService: BottomSheetService,
  ) {
    super(translateService, appSettingsService);
  }

  beginSelection(): void {
    this.bottomSheetService.open(
      this.roomGuestFieldMobileTemplate,
      ["room-guest-field-mobile", "search-form"],
    );
  }

  closeModal(): void {
    this.bottomSheetService.close();
  }

  protected showLabelAsGuest(): string {
    if (this.adultCount === 1) {
      return this.translateService.instant("Single Guest Count");
    } else {
      return this.translateService.instant("Multiple Guest Count", { count: this.adultCount });
    }
  }

  protected separator(): string {
    return `<span class="separator"> | </span>`;
  }
}
